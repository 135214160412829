





























import { Component } from 'vue-property-decorator'

import { AbstractModuleForm } from '../../_abstract'
import { ColorSchemaSelector } from '../../../molecules'

import { newsletterContentFactory } from '../Newsletter.factory'
import { NewsletterModule, NewsletterModuleContent } from '../Newsletter.contracts'

/**
 * TODO: Document all methods and properties.
 * TODO: Add `@author` annotation for the original author of the file.
 *
 * @author ??? (original)
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl> (edited)
 */
@Component({
  name: 'NewsletterModuleForm',
  components: { ColorSchemaSelector }
})
export class NewsletterModuleForm extends AbstractModuleForm<NewsletterModule> {
  public initialContent: NewsletterModuleContent = newsletterContentFactory()
}

export default NewsletterModuleForm
